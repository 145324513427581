var fixedmenu = (function(){
	var nav = $('.navbar-nav');
	var topo =  $('.navbar-topo');
	var currentPosition = 0;
	var fixed = false;
	var interval = setInterval(function() {
		var scrollTop = $(window).scrollTop();

		if(scrollTop !== currentPosition) {
			var offset = topo.outerHeight() - nav.outerHeight() - 10;

			topo[0].style.setProperty('--top', -offset+'px');

			if(scrollTop >= offset) {
				topo.addClass('affix');

				if(!fixed) {
					topo.after('<div id="topo__spacer" style="height: '+topo.outerHeight()+'px"></div>');
				}

				fixed = true;
			}else {
				topo.removeClass('affix');
				$('#topo__spacer').remove()
				fixed = false;
			}

			currentPosition = scrollTop;
		}

	}, (1000/60));
})();

// $('.navbar-topo').affix({
//   offset: {
//     top: function () {
//       return (this.bottom = $('.navbar-topo').innerHeight())
//     }
//   }
// });