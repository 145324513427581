$('[data-scrolling]').each(function () {
  var target = $(this).attr('href');
  var $target = $(target);

  $(this).click(function (event) {
    event.preventDefault();
  	console.log($target.offset().top);

    window.scrollTo({
    	top: $target.offset().top,
    	behavior: 'smooth'
    })
  });

});